import React, {FC, useCallback, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";

interface Props {
}

const SectionText: FC<Props> = () => {
  const {t} = useTranslation();
  const [idx, setTitleIdx] = useState(0);

  useEffect(() => {
    document.body.classList.add("no-scroll");
    const handleWhell = (e: any) => {
      const hasModal = Boolean(document.querySelector('.react-responsive-modal-root'));
      if (hasModal) return;
      const scrollY = window.scrollY;
      const deltaY = e.deltaY;
      if (scrollY <= 0 && deltaY < 0) {
        if (deltaY < 0) {
          setTitleIdx(0);
          document.body.classList.add("no-scroll");
        } else {
          setTitleIdx(1);
          window.scrollTo(0, 1);
          setTimeout(() => document.body.classList.remove("no-scroll"), 300);
        }
      } else {
        setTitleIdx(1);
        setTimeout(() => document.body.classList.remove("no-scroll"), 300);
      }
    };
    window.addEventListener('wheel', handleWhell);
    return () => {
      window.removeEventListener('wheel', handleWhell);
    };
  }, []);

  const handlePaginationClick = useCallback((idx: number) => () => {
    setTitleIdx(idx);
    if(idx === 0) {
      document.body.classList.add("no-scroll")
    } else {
      document.body.classList.remove("no-scroll")
    }
  }, [])

  return (
    <div className='position-relative'>
      <div className={`section-text-wrap${idx === 1 ? ' second' : ''}`}>
        {Array(2).fill({}).map(((item, i) => (
          <div key={i} className={`section-text-item`}>
            <h2 className='section-title'>
              <span dangerouslySetInnerHTML={{__html: t(`SECTION_1_TITLE_${i}`) || ''}}/>
            </h2>
            <p className='muted section_1-text'>{t(`SECTION_1_TEXT_${i}`)}</p>
          </div>
        )))}
      </div>
      <div className='section-text-pagination'>
        {Array(2).fill({}).map((item, i) => (
          <div
            key={i}
            className={`section-text-pagination-item${i === idx ? ' active' : ''}`}
            onClick={handlePaginationClick(i)}
          />
        ))}
      </div>
    </div>
  );
}

export default SectionText;