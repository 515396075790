import React, {ChangeEvent, FC, InputHTMLAttributes, useCallback, useState} from 'react';

import {useTranslation} from "react-i18next";
import {ReactSVG} from "react-svg";
import attach_svg from '../../assets/images/icons/attach.svg';
import close_svg from '../../assets/images/icons/close.svg';
import {API} from "../../utils/api";
import {IAsset} from "../../utils/rest";

// @ts-ignore
interface Props extends InputHTMLAttributes<any> {
  label?: string;
  className?: string;
  error?: boolean;
  icon?: any;
  acceptText?: string;
  listFiles?: any[];
  onRemoveFile?: (id: number) => void;
  onChange: (file: IAsset, remove?: boolean) => void;
}

const InputFile: FC<Props> = ({
                                label,
                                className,
                                icon,
                                listFiles,
                                acceptText,
                                onChange,
                                error,
                                ...props
                              }) => {
  const {t} = useTranslation();

  const [asset, setAsset] = useState<IAsset|null>();
  const [progress, setProgress] = useState<number>(0);
  const [uploading, setUploading] = useState<boolean>(false);

  const change = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const file: File = e.target.files![0];

      e.target.value = '';

      // if (asset) onChange(null);

      const fileSize = file.size;
      const chunkSize = 1024 * 512;

      if (fileSize < chunkSize) {
        const form = new FormData();
        form.append('upload', file);
        form.append('name', file.name);
        setUploading(true);
        API.Assets.uploadForm(form)
          .then((asset) => {
            onChange(asset);
          })
          .finally(() => setUploading(false))
          .catch(alert);
        return;
      }

      let offset = 0;
      let id: string;

      const chunkReaderBlock = () => {
        const reader = new FileReader();
        const blob = file.slice(offset, chunkSize + offset);
        const sendChunk = () => {
          const chunk = (reader.result as string).split(',')[1];
          API.Assets.chunkPartial({
            id,
            chunk,
          })
            .then((res) => {
              setProgress(res.progress);
              if (res.isReady) {
                setUploading(false);
                onChange(res.asset as IAsset);
              } else {
                offset += chunkSize;
                chunkReaderBlock();
              }
            })
            .catch((e) => {
              // console.log();(e.message + ', retry in 3 sec....');
              setTimeout(sendChunk, 3000);
            });
        };

        reader.onload = sendChunk;
        reader.readAsDataURL(blob);
      };

      setProgress(0);
      setUploading(true);

      API.Assets.beginPartial({
        fileSize,
        chunkSize,
        fileType: file.type,
        fileName: file.name,
      })
        .then((res) => {
          id = res.id;
          chunkReaderBlock();
        })
        .catch((e) => {
          setUploading(false);
          console.error(e);
        });
    },
    [onChange, asset]
  );

  return (
    <div className={`form-group${error ? ' error' : ''} ${className || ''}`}>
      {label ?
        <label className='muted mb-2'>
          {t(label)}
          {props.required ? <span className='text-danger text-12'> *</span> : null}
        </label>
        : null}
      <div className='form-group-file'>
        <div className='form-file-label-wrap'>
          <label className='form-file-label'>
            <input className={`form-control${icon ? ' form-control-icon' : ''}`} {...props} required={false} type='file' onChange={change}/>
            <div className='d-flex align-items-center'>
              <ReactSVG src={attach_svg}/>
              <span className='ps-2'>{t('ATTACH')}</span>
            </div>
          </label>
          {uploading && (
            <div className="input-file__progress" style={{backgroundImage: `radial-gradient(closest-side, #201F24 60%, transparent 80% 100%),conic-gradient(#CC4DDF ${progress}%, rgba(255, 255, 255, 0.1) 0)`}} />
          )}
          {acceptText ? <div>{acceptText}</div> : null}
        </div>
        <div className='d-flex flex-wrap'>
          {listFiles?.length
            ?
            listFiles.map((file, i) => (
              <div key={i} className='form-file-list-item'>
                <div className='text-truncate'>{file.name}</div>
                <div className='ms-2 cur-pointer' onClick={() => onChange(file, true)}><ReactSVG src={close_svg}/>
                </div>
              </div>
            ))
            :
            <div className='form-group-file-placeholder pb-2'>{props.placeholder}</div>
          }
        </div>
      </div>
    </div>
  );
}

export default InputFile;