import React, {FC} from 'react';
import {useTranslation} from "react-i18next";
import note from '../../assets/images/note.svg';

import './section.css'
import SectionTitle from "../../components/Sectiontitle";
import {EMusicPlatforms} from "../../utils/iApp";
import MusicPlatformIcon from "../../components/MusicPlatformIcon";

const settings = {
  dots: false,
  arrows: false,
  infinite: true,
  speed: 500,
  slidesToScroll: 1,
  slidesToShow: 10
  // autoplay: true,
  // autoplaySpeed: 2000,
};

interface Props {
}

const Section3: FC<Props> = () => {
  const {t} = useTranslation();
  return (
    <section id={'section_3'}>
      <SectionTitle title='SECTION_3_TITLE' icon={note}/>
      <div className='section-3-platforms'>
        {Object.entries(EMusicPlatforms).map(([key, value], i) => (
          <MusicPlatformIcon platform={value} className='section-3-platforms-item' key={i}/>
        ))}
      </div>
      <div className='section-3-slideshow'>
        <div className="slideshow">
          <div className="slideshow-wrap">
            {Object.entries(EMusicPlatforms).map(([key, value], i) => (
              <div className='slideshow-item-wrap' key={i}>
                <MusicPlatformIcon platform={value} className='slideshow-item btn-primary btn'/>
              </div>
            ))}
            {Object.entries(EMusicPlatforms).map(([key, value], i) => (
              <div className='slideshow-item-wrap' key={i}>
                <MusicPlatformIcon platform={value} className='slideshow-item btn-primary btn'/>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Section3;