import React, {FC} from 'react';
import './footer.css'
import {useTranslation} from "react-i18next";
import {POLICY, TERMS} from "../../utils/links";

interface Props {
}

const Footer: FC<Props> = () => {
  const {t} = useTranslation();
  return (
      <footer>
        <div className='d-flex align-items-center'>
        <a href={POLICY} target='_blank' className='footer-link'>{t('POLICY')}</a>
        <div>|</div>
        <a  href={TERMS} target='_blank' className='footer-link'>{t('TERMS')}</a>
        </div>
        <div className='mt-2 muted-12 text-center'>
          Copyright © 2023 AXTUNE
          Contact us at <a  href={'mailto:info@axtune.com'} target='_blank'>info@axtune.com</a>
        </div>
      </footer>
  );
}

export default Footer;