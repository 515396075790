import React, {useCallback, useEffect, useState} from 'react'
import Section1 from "./sections/Section1";
import Section2 from "./sections/Section2";
import Section3 from "./sections/Section3";
import Section4 from "./sections/Section4";
import Section5 from "./sections/Section5";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Preview from "./sections/Preview";
import {API} from "./utils/api";
import {IUser} from "./utils/rest";

function App() {
  const [ready, setReady] = useState(false);
  const [user, setUser] = useState<IUser>();

  useEffect(() => {
    // init();
  }, []);

  const init = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      if (token) {
        const res = await API.Users.getMe();
        API.setToken(token);
        setUser(res);
      }
    } catch (e) {
    } finally {
      setReady(true);
    }
  }, []);

  // if (!ready) return <Preview/>

  return (
    <>
      <Header setUser={setUser}/>
      <main className='main'>
        <Section1/>
        <Section2/>
        <Section3/>
        <Section4/>
        <Section5 user={user}/>
      </main>
      <Footer/>
    </>
  )
}

export default App;
