import React, {FC} from 'react';
import apple_platform from "../assets/images/platform_logo/apple.svg";
import spotify_platform from "../assets/images/platform_logo/spotify.svg";
import yandex_platform from "../assets/images/platform_logo/yandex.svg";
import deezer_platform from "../assets/images/platform_logo/deezer.svg";
import google_platform from "../assets/images/platform_logo/google.svg";
import amazon_platform from "../assets/images/platform_logo/amazon.svg";
import shazam_platform from "../assets/images/platform_logo/shazam.svg";
import vk_platform from "../assets/images/platform_logo/vk.svg";
import itunes_platform from "../assets/images/platform_logo/itunes.svg";
import youtube_music_platform from "../assets/images/platform_logo/youtube_music.svg";
import youtube_red_platform from "../assets/images/platform_logo/youtube_red.svg";
import {ReactSVG} from "react-svg";
import {EMusicPlatforms} from "../utils/iApp";

const platforms: any = {
  appleMusic: {img: apple_platform, link: '#'},
  spotify: {img: spotify_platform, link: '#'},
  yandexMusic: {img: yandex_platform, link: '#'},
  deezer: {img: deezer_platform, link: '#'},
  amazon: {img: amazon_platform, link: '#'},
  google: {img: google_platform, link: '#'},
  shazam: {img: shazam_platform, link: '#'},
  // vk: {img: vk_platform, link: '#'},
  itunes: {img: itunes_platform, link: '#'},
  youtube_music: {img: youtube_music_platform, link: '#'},
  youtube_red: {img: youtube_red_platform, link: '#'},
}

interface Props {
  platform: string;
  link?: boolean;
  href?: string;
  className?: string;
}

const MusicPlatformIcon: FC<Props> = ({platform, link, href, className}) => {
  const item = platforms[platform];
  if (!item || (link && !href)) return null;
  // if (!item ) return null;
  if (link) return (
    <a href={href} className={className} target='_blank'>
      <div className='platform-icon'><ReactSVG src={item.img}/></div>
    </a>
  )
  return (
    <div className={className}>
      <div className='platform-icon'><ReactSVG src={item.img}/></div>
    </div>
  );
}

export default MusicPlatformIcon;