import React, {FC} from 'react';
import './pagination.css';

interface Props {
  i: number;
  horizontal?: boolean;
  big?: boolean;
  className?: string;
}

const Pagination: FC<Props> = ({i, horizontal, className}) => {
  return (
    <div className={`pagination-item pagination-item_${i + 1}${horizontal ? ' horizontal' : ''} ${className || ''}`}/>
  );
}

export default Pagination;