import {toast as toastify} from 'react-toastify';

export const availableLangs = ['en', 'es', 'ar'];

export const getLang = (ln?: string) => {
  //@ts-ignore
  if (!ln) ln = localStorage.getItem('ln') || (window?.navigator?.userLanguage || window.navigator.language).substring(0, 2);
  const availableList: any = {
    es: ['es',  'mx', 'co', 'pe', 've', 'cl', 'ec', 'gt', 'cu', 'bo', 'do', 'hn', 'py', 'sv', 'ni', 'cr', 'pr', 'pa', 'uy', 'gy', 'jm', 'bs', 'tt', 'bz', 'ht'],
    ar: ['ar'],
    ru: ['ru', 'uk', 'kk', 'be', 'az', 'lv', 'ee', 'tr', 'ka', 'hy', 'uz', 'mo', 'bg'],
  }
  Object.entries(availableList).forEach(([lang, list]: any) => {
    if (list.includes(ln)) ln = lang
  })
  if(ln === 'ar') {
    document.querySelector("html")?.setAttribute("dir", 'rtl');
  } else {
    document.querySelector("html")?.setAttribute("dir", 'ltr');
  }
  return availableLangs.includes(ln!) ? ln : 'en'
}


const toastConfig = {
  position: 'top-right',
  autoClose: 4000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: false,
  progress: undefined,
};

export function toast(text: string, type = 'error') {
  if (!text) return null;
  // @ts-ignore
  toastify[type](text, toastConfig);
  // @ts-ignore
  // toastify(Toast, {...toastConfig, data: {type, text}});
}
