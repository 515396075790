import React, {FC} from 'react';
import './Loadable.css';
import Spinner from "./Spinner";

interface Props {
  loading: boolean;
  className?: string;
  children: any;
}

const Loadable: FC<Props> = ({loading, className, children}) => {
  return (
    <div className={`loadable${loading ? ' loading' : ''} ${className || ''}`}>
      {children}
      {loading && (
        <div className="spinner">
          <Spinner />
        </div>
        )}
    </div>
  );
};

export default Loadable;
