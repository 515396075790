import { RestAPI } from './rest';
import i18next from "i18next";

const isDev = !!window.location.host.match(/(localhost|127\.|192\.)/);
const endpoint = isDev ? 'http://192.168.1.223:8004' : 'https://api.axtune.com';
const domain = isDev ?  `localhost:${window.location.port}` :'axtune.com'


const API = new RestAPI(endpoint, isDev);

API.setHeadersHandler((headers) => {
  headers['Accept-Language'] = i18next.language;
});

export { API, isDev, endpoint, domain };
