import React, {FC} from 'react';
import './spinner.css'

interface Props {

}

const Spinner: FC<Props> = ({}) => {

  return (
    <div className="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}

export default Spinner;