import React from 'react';
import {FC, InputHTMLAttributes} from 'react';
import {ReactSVG} from "react-svg";
import check_svg  from '../../assets/images/icons/check.svg';

interface Props extends InputHTMLAttributes<any> {
  className?: string;
}

const Checkbox: FC<Props> = ({
                               className,
                               ...props
                             }) => {
  return (
    <label className={`form-check ${className ?? ''}`}>
      <input
        className="form-check-input"
        {...props}
        type="checkbox"
      />
      <div className='form-check-custom'>
        <ReactSVG src={check_svg} />
      </div>
    </label>
  );
};

export default Checkbox;
