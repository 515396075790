import React, {FC, useState} from 'react';
import {useTranslation} from "react-i18next";

import './section.css'
import SectionTitle from "../../components/Sectiontitle";
import invite from "../../assets/images/invite.svg";
import SectionFormSuccess from "./SectionFormSuccess";
import SectionForm from "./SectionForm";
import {IUser} from "../../utils/rest";

interface Props {
  user?: IUser;
}

const Section5: FC<Props> = ({user}) => {
  const {t} = useTranslation();
  const [success, setSuccess] = useState(false);

  return (
    <section id={'section_5'}>
      <SectionTitle title='SECTION_5_TITLE' icon={invite} className='mb-4'/>
      <p className='section_5-text muted'>{t('SECTION_5_TEXT')}</p>
      {success
        ?
        <SectionFormSuccess/>
        :
        <SectionForm onSuccess={() => setSuccess(true)}/>
      }
    </section>
  );
}

export default Section5;