import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from './translates/en.json';
import translationES_latam from './translates/es_latam.json';
import translationAR from './translates/ar.json';
import {getLang} from "./utils/utils";


// the translations
const resources = {
  en: {
    translation: translationEN
  },
  es: {
    translation: translationES_latam
  },
  ar: {
    translation: translationAR
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: getLang(),
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
