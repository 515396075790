import React, {FC} from 'react';
import Slider from "react-slick";
import TrackInfo from "./TrackInfo";
import {useTranslation} from "react-i18next";
import {IAlbum} from "../../utils/rest";
import {thumb} from "./Section1";

interface Props {
  tracks: IAlbum[];
}

const MobileTracksSider: FC<Props> = ({tracks}) => {
  const {t} = useTranslation();
  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '24px',
    autoplay: true,
    autoplaySpeed: 6000
  };
  return (
    <div className='pt-4'>
      <div className='muted mb-3'>{t('LAST_RELEASES')}</div>
      <div className='tracks-slick'>
        <Slider {...settings}>
          {tracks.map((item: any, i: number) => (
            <div key={i} className={`tracks-slick-item`}>
              <div key={i} className={`tracks-slick-item-content`}>
                <img src={thumb(item?.cover?.id, 600)} alt="item"/>
                <TrackInfo track={item} className='pt-2 ps-2 ms-1'/>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default MobileTracksSider;