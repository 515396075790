export enum EMusicPlatforms {
  Apple = 'apple',
  Spotify = 'spotify',
  Yandex = 'yandex',
  Deezer = 'deezer',
  Amazon = 'amazon',
  Google = 'google',
  Shazam = 'shazam',
  // Vk = 'vk',
  Itunes = 'itunes',
  Youtube_music = 'youtube_music',
  Youtube_red = 'youtube_red',
}