import React, {FC} from 'react';
import {useTranslation} from "react-i18next";

import './section.css'
import Pagination from "../../components/Pagination";

interface Props {
}

const Section2: FC<Props> = () => {
  const {t} = useTranslation();
  return (
    <section id={'section_2'} className='container-fluid p-0'>
      <div className="row">
        {Array(3).fill({}).map((item, i) => (
          <div className="col-12 col-md-4" key={i}>
            <div className='section_2-item'>
              <div className="section_2-item-content">
                <div className='bold'>{t(`SECTION_2_ITEM_${i + 1}_TITLE`)}</div>
                <div>{t(`SECTION_2_ITEM_${i + 1}_TEXT`)}</div>
                <Pagination i={i} className='section_2-pagination'/>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Section2;