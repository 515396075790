import React, {FC} from 'react';
import {ReactSVG} from "react-svg";
import {useTranslation} from "react-i18next";

interface Props {
  icon?: any;
  title: string;
  className?: string;
}

const SectionTitle: FC<Props> = ({icon, title, className}) => {
  const {t} = useTranslation();
  return (
    <h2 className={`section-title ${className || ''}`}>
      {icon
        ?
        <div className='section-title-icon'>
          <ReactSVG src={icon}/>
        </div>
        :
        null
      }
      <span>{t(title)}</span>
    </h2>
  );
}

export default SectionTitle;