import React, {FC, useCallback, useEffect, useState} from 'react';
import {Input, InputFile, Textarea} from "../../components/FormControls";
import user_svg from "../../assets/images/icons/user.svg";
import beats_svg from "../../assets/images/icons/beats.svg";
import unpublished_tracks_svg from "../../assets/images/icons/unputlish_tracks.svg";
import mail_svg from "../../assets/images/icons/mail.svg";
import track_svg from "../../assets/images/icons/track.svg";
import link_svg from "../../assets/images/icons/link.svg";
import question_svg from "../../assets/images/icons/question.svg";
import whatsapp_svg from "../../assets/images/icons/whatsapp.svg";
import tg_svg from "../../assets/images/icons/tg.svg";
import viber_svg from "../../assets/images/icons/viber.svg";
import Pagination from "../../components/Pagination";
import {useTranslation} from "react-i18next";
import {IAsset, IPerformerApplicationSubmitRequest} from "../../utils/rest";
import {API} from "../../utils/api";
import {POLICY, TERMS} from "../../utils/links";
import Checkbox from "../../components/FormControls/Checkbox";
import Loadable from "../../components/Loadable";
import {toast} from "../../utils/utils";

interface Props {
  onSuccess: () => void;
}

const SectionForm: FC<Props> = ({onSuccess}) => {
  const {t} = useTranslation();
  const [form, setForm] = useState<IPerformerApplicationSubmitRequest>({
    name: '',
    email: '',
    contacts: '',
    samples: [],
    bio: '',
    questions: '',
    unpublishedTracks: '',
    originalBeats: '',
  });
  const [contacts, setContacts] = useState({whatsapp: '', tg: '', viber: ''});
  const [contactsError, setContactsError] = useState(false);
  const [sampleError, setSampleError] = useState(false);
  const [samples, setSamples] = useState<IAsset[]>([]);
  const [checkbox, setCheckbox] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    let utm_source = urlParams.get('utm_source');
    if (utm_source) {
      localStorage.setItem('utm_source', utm_source);
    }
  }, []);

  const handleChange = useCallback((key: any) => (e: any) => {
    setForm(prevState => {
      return {...prevState, [key]: e.target.value}
    })
  }, []);

  const handleChangeContacts = useCallback((contact: string) => (e: any) => {
    setContactsError(false);
    setContacts(prevState => {
      return {...prevState, [contact]: e.target.value}
    });
  }, []);

  const handleUpload = useCallback((sample: IAsset, remove?: boolean) => {
    if (remove) {
      setSamples(prevState => {
        const idx = prevState.findIndex(item => item.id === sample.id);
        return [...prevState.slice(0, idx), ...prevState.slice(idx + 1)]
      });
    } else {
      setSamples(prevState => {
        return [...prevState, sample]
      })
    }
    setSampleError(false);
  }, []);

  const handleSubmit = useCallback(async (e: any) => {
    e.preventDefault();
    if (loading) return;
    if (!samples?.length) return setSampleError(true);
    try {
      let resContacts = '';
      let contactError = true;
      Object.entries(contacts).forEach(([key, value], i) => {
        if (!value) return;
        contactError = false;
        resContacts += `${i ? ', ' : ''}${key}: ${value}`
      });
      if (contactError) return setContactsError(true);
      setLoading(true);
      await API.Applications.submitPerformerApplication({
        ...form,
        samples: samples.map(item => item.id),
        contacts: resContacts,
        source: localStorage.getItem('utm_source') || null,
      });
      onSuccess();
      //@ts-ignore
      ym(94487142, 'reachGoal', 'submit')
    } catch (e: any) {
      toast(e)
    } finally {
      setLoading(false);
    }
  }, [form, samples, onSuccess, contacts, loading]);

  return (
    <form className='section_5-form' onSubmit={handleSubmit}>
      <p className='bold'>
        {t('FORM_TITLE')}

      </p>
      <div className="row">
        <div className="col-12 col-lg-6 pe-lg-3">
          <Input
            className='mb-4'
            icon={user_svg}
            type='text'
            placeholder={t('NAME_PLACEHOLDER') || ''}
            label='NAME'
            value={form.name}
            onChange={handleChange('name')}
            required
          />
          <Input
            className='mb-4'
            icon={mail_svg}
            type='email'
            placeholder={t('EMAIL_PLACEHOLDER') || ''}
            label='EMAIL'
            value={form.email}
            onChange={handleChange('email')}
            required
          />
          <InputFile
            error={sampleError}
            required
            listFiles={samples}
            accept='.mp3,.wav'
            acceptText='wav / mp3'
            className='mb-4'
            icon={track_svg}
            placeholder={t('SAMPLES_TRACKS_PLACEHOLDER') || ''}
            label='SAMPLES_TRACKS'
            value={form.samples}
            onChange={handleUpload}
          />
          <Input
            required
            className='mb-4'
            icon={beats_svg}
            type='text'
            // placeholder={t('NAME_PLACEHOLDER') || ''}
            label='ORIGINAL_BEATS'
            value={form.originalBeats}
            onChange={handleChange('originalBeats')}
          />
        </div>
        <div className="col-12 col-lg-6 ps-lg-3">
          <Textarea
            className='mb-4'
            icon={link_svg}
            placeholder={t('SOC_LINKS_PLACEHOLDER') || ''}
            label='SOC_LINKS'
            value={form.bio}
            onChange={handleChange('bio')}
            required
          />
          <Textarea
            className='mb-4'
            icon={question_svg}
            placeholder={t('QUESTIONS_PLACEHOLDER') || ''}
            label='QUESTIONS'
            value={form.questions || ''}
            onChange={handleChange('questions')}
          />
          <Input
            required
            className='mb-4'
            icon={unpublished_tracks_svg}
            type='text'
            label='UNPUBLISHED_TRACKS_LABEL'
            value={form.unpublishedTracks}
            onChange={handleChange('unpublishedTracks')}
          />
        </div>
      </div>
      <div className='mt-2 mt-lg-3 pb-4'>
        <div className='bold mb-3 mb-lg-4'>{t('MESSENGER')}</div>
        <div className='row'>
          <div className="col-12 col-md-4 pe-lg-3">
            <Input
              error={contactsError}
              className='mb-2 pb-1 pb-md-0 mb-md-0'
              icon={whatsapp_svg}
              type='text'
              placeholder={t('WHATSAPP_PLACEHOLDER') || ''}
              label='WhatsApp'
              value={contacts.whatsapp}
              onChange={handleChangeContacts('whatsapp')}
            />
          </div>
          <div className="col-12 col-md-4 pe-lg-3">
            <Input
              error={contactsError}
              className='mb-2 pb-1 pb-md-0 mb-md-0'
              icon={tg_svg}
              type='text'
              placeholder={t('TG_PLACEHOLDER') || ''}
              label='Telegram'
              value={contacts.tg}
              onChange={handleChangeContacts('tg')}
            />
          </div>
          <div className="col-12 col-md-4 ps-lg-3">
            <Input
              error={contactsError}
              icon={viber_svg}
              type='text'
              placeholder={t('VIBER_PLACEHOLDER') || ''}
              label='Viber'
              value={contacts.viber}
              onChange={handleChangeContacts('viber')}
            />
          </div>
        </div>
      </div>
      <div
        className='d-flex flex-column flex-lg-row justify-content-center justify-content-lg-between align-items-center mt-lg-3'>
        <Pagination i={0} horizontal/>
        <div className='section_5-terms muted'>
          <Checkbox checked={checkbox} onChange={(e) => setCheckbox(e.target.checked)} className='me-3'/>
          <div dangerouslySetInnerHTML={{__html: t('POLICY_PERMISSION', {policy: POLICY, terms: TERMS}) || ''}}/>
        </div>
        <Loadable loading={loading}>
          <button type='submit' className='btn-gradient w-100' disabled={!checkbox} style={{minWidth: 200}}>
            {t('REGISTRATION')}
          </button>
        </Loadable>
      </div>
    </form>
  );
}

export default SectionForm;
