import React, {FC} from 'react';
import {useTranslation} from "react-i18next";

import './section.css'
import clap from "../../assets/images/clap.svg";
import star from "../../assets/images/star.svg";
import coins from "../../assets/images/coins.svg";
import Pagination from "../../components/Pagination";
import SectionTitle from "../../components/Sectiontitle";
import {REG_LINK} from "../../utils/links";

interface Props {
}

const Section4: FC<Props> = () => {
  const {t} = useTranslation();
  return (
    <section id={'section_4'}>
      <SectionTitle title='SECTION_4_TITLE_1' icon={clap}/>
      <div className='section_4-wrap'>
        <div className='section_4-content'>
          <div className='section_4-items'>
            <div className='section_4-items-wrap'>
              {Array(3).fill({}).map((itm, i) => (
                <div className={`section_4-item section_4-item_${i + 1}`} key={i}>
                  <div className='section_4-item-content'>
                    <div className='section_4-item-title bold'>
                      <div>{t(`SECTION_4_ITEM_${i + 1}_TITLE`)}</div>
                      <div>(0{i + 1})</div>
                    </div>
                    <p>{t(`SECTION_4_ITEM_${i + 1}_TEXT`)}</p>
                  </div>
                </div>
              ))}
            </div>
            <Pagination i={0} horizontal className='section_4-pagination'/>
          </div>
          <SectionTitle title='SECTION_4_TITLE_2' icon={star} className='section_4-title_2'/>
          <p className='section_4-text_2 muted'>{t('SECTION_4_TEXT_2')}</p>

          <SectionTitle title='SECTION_4_TITLE_3' icon={coins} className='section_4-title_3'/>
          <div className='section_4-text_3 muted'>
            <p className='mb-3 mb-lg-4'>{t('SECTION_4_TEXT_3_1')}</p>
            <p>{t('SECTION_4_TEXT_3_2')}</p>
          </div>
        </div>
        <div className='section_4-sider'>
          <div className='section_4-btn'>
            <div className="section_4-btn-content">
              <div className='bold'>{t('SECTION_4_TEXT_4')}</div>
              <a href={'#section_5'} className='btn-gradient'>{t('REGISTRATION')}</a>
            </div>
          </div>
        </div>
      </div>
      <div className='section_4-wave'></div>
    </section>
  );
}

export default Section4;