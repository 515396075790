import React, {FC, useLayoutEffect} from 'react';
import {useTranslation} from "react-i18next";
import Slider from "react-slick";

interface Props {
}

const SectionText: FC<Props> = () => {
  const {t} = useTranslation();

  useLayoutEffect(() => {
    document.body.classList.remove("no-scroll")
  },[])
  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000
  };
  return (
    <div className={`section-text-slider`}>
      <Slider {...settings}>
      {Array(2).fill({}).map(((item, i) => (
        <div key={i} className={`section-text-slider-item`}>
          <h2 className='section-title'>
            <span dangerouslySetInnerHTML={{__html: t(`SECTION_1_TITLE_${i}`) || ''}}/>
          </h2>
          <p className='muted section_1-text'>{t(`SECTION_1_TEXT_${i}`)}</p>
        </div>
      )))}
      </Slider>
    </div>
  );
}

export default SectionText;